.searchContainer {
  position: relative;
  display: flex;
}

.searchContainerInput {
  border: 1px solid grey;
  border-radius: 5px;
  height: 3rem;
  width: 100%;
  padding: 2px 23px 2px 30px;
  outline: 0;
  background-color: #f5f5f5;
}

.searchContainerIcon {
  position: relative;
  top: 0.7rem;
}

.searchContainerSearchIcon {
  left: 3rem;
}

.searchContainerClearIcon {
  right: 2rem;
  cursor: pointer;
}

@media all and (min-width: 768px) {
  .searchContainerInput {
    height: 5rem;
  }

  .searchContainerIcon {
    top: 1.7rem;
  }
}
