.hero {
  background-image: url("../../Assets/Images/personal/banner_image.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 42rem;
  padding: 0;
  position: relative;
}

.heroShort {
  height: 20rem;
}

.heroMusic {
  background-image: url("../../Assets/Images/about/music/generic_background.png");
}

.heroCv {
  background-image: url("../../Assets/Images/work/paralax-1.jpg");
}

.heroCover {
  position: relative;
  z-index: 3;
}

.heroCover::before {
  background: url("../../Assets/Images/personal/banner-pattern.png");
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.heroCover::after {
  background: #000;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -2;
}

.heroIntro {
  display: flex;
  flex-direction: column;
  height: 42rem;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.heroIntroShort {
  height: 20rem;
}

@media all and (min-width: 768px) {
  .hero {
    height: 55rem;
  }

  .heroShort {
    height: 30rem;
  }

  .heroIntro {
    height: 55rem;
  }

  .heroIntroShort {
    height: 30rem;
  }
}

@media all and (min-width: 992px) {
  .hero {
    height: 68rem;
  }

  .heroShort {
    height: 40rem;
  }

  .heroIntro {
    height: 68rem;
  }

  .heroIntroShort {
    height: 40rem;
  }
}
