.achievementContainer {
  margin-bottom: 1.5rem;
}

.achievementTitle {
  color: #666;
  font-weight: 700;
  font-size: 1.3em;
  text-decoration: underline;
  margin-top: 1rem;
}

.achievements {
  margin-left: 20px;
  list-style: circle;
}
