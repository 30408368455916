@import-normalize;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-size: 62.5%;
}

body {
  background: #333;
  color: #353535;
  font-family: "Open Sans", sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  height: 100%;
  line-height: 2.4rem;
  margin: 0;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #16a085;
  text-decoration: none;
}

/* Header */

.section-title__header--white {
  color: #fff;
}

/* Common */
.img--round {
  border-radius: 50%;
}

.hidden {
  display: none;
}
