.subTitle {
  color: #fff;
  display: inline-block;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  position: relative;
  text-transform: uppercase;
}

.subTitle::before {
  background: #16a085;
  border-radius: 100%;
  bottom: -1px;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  width: 70%;
}
