.container {
  width: 5rem;
  height: 5rem;
  overflow: hidden;
  border-radius: 50%;
}

.img {
  display: inline;
  margin: 0 auto;
  height: 100%;
  width: auto;
}
