.boxWrapper {
  margin-top: 30px;
  width: 100%;
}

.box {
  margin: 10px;
  text-align: center;
  background: #fff;
  padding: 25px;
  box-shadow: 0 0 7px rgb(0 0 0 / 20%);
  transition: all 0.3s ease;
  position: relative;
  min-height: 220px;
}

.boxWithHover:hover {
  background-color: #16a085;
  color: #fff;
}

.boxIcon {
  font-size: 4.5rem;
}

.boxHeader {
  font-size: 18px;
  margin: 1.5rem 0;
}

@media all and (min-width: 768px) {
  .boxWrapper {
    width: 50%;
  }
}

@media all and (min-width: 1200px) {
  .boxWrapper {
    width: 25%;
  }

  .boxWrapperLarge {
    width: 50%;
  }
}
