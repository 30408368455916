.containerInfo {
  height: 4.5rem;
  text-align: left;
}

@media all and (min-width: 420px) {
  .containerInfo {
    text-align: center;
  }
}
