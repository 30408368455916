.header {
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9999;
  padding: 10px;
}

.menuContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
