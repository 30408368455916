.title {
  color: #fff;
  font-size: 2.5rem;
  font-weight: 800;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.sunken {
  margin-top: 5rem;
}

@media all and (min-width: 420px) {
  .title {
    font-size: 3.2rem;
  }
}

@media all and (min-width: 768px) {
  .title {
    font-size: 5rem;
  }
}
