.album {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.albumCover {
  width: 80%;
}
.albumCoverImg {
  border-bottom: 5px solid #16a085;
  width: 100%;
}

.albumInfo {
  padding-bottom: 2rem;
  width: 80%;
}

.albumDate {
  position: relative;
  background: #333;
  left: 5%;
  width: 30%;
  color: #fff;
  display: block;
  text-align: center;
  font-size: 13px;
  text-transform: uppercase;
  top: -35px;
  z-index: 1;
}

.albumDateMonth {
  background-color: #16a085;
}
