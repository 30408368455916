.arrow {
  font-size: 30px;
  position: absolute;
  top: -15px;
  left: 50%;
  color: #fff;
  width: 44px;
  height: 44px;
  line-height: 44px;
  border-radius: 100%;
  margin-left: -22px;
  display: block;
  padding: 0;
  z-index: 99;
  background: #16a085;
}

.footer {
  padding: 35px 0 10px;
  padding: 15px 0 10px;
  text-align: center;
  position: relative;
}

.footerContent {
  color: #fff;
  margin-top: 20px;
}

@media all and (min-width: 768px) {
  .footerContent {
    display: flex;
    justify-content: space-between;
  }
}
