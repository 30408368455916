.cvIntro {
  justify-content: start;
  padding-top: 100px;
}

.subTitle {
  color: #eee;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.2rem;
  margin-bottom: 2.5rem;
  text-transform: uppercase;
}

.roleContainer {
  overflow: hidden;
  position: relative;
  text-align: left;
}

.roleContainerItem {
  color: #16a085;
  display: inline-block;
  font-weight: 700;
  left: 0;
  position: relative;
  top: 0;
  white-space: normal;
}

.roleSelector {
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  color: #fff;
}

.role {
  max-width: 33.3%;
}

.roleTitle {
  margin-top: 20px;
  font-size: 18px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

@media all and (min-width: 992px) {
  .cvIntro {
    height: 61rem;
  }

  .subTitle {
    font-size: 2.5rem;
  }
}
