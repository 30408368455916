.periodContainer {
  margin: 2rem 0rem;
}

.period {
  display: flex;
  justify-content: space-between;
}

.periodRow {
  text-align: left;
  width: 50%;
}

.periodRowImg {
  width: 80%;
}

.periodSeparator {
  border: 0;
  border-top: 1px solid #eee;
}

.periodTitle {
  text-align: left;
  text-transform: uppercase;
  border-bottom: 2px solid #16a085;
  border-bottom-width: medium;
  font-size: 16px;
}

.periodRange {
  font-size: 16px;
}
