/* Testimonials */

.testimonials {
  background-position: 50% 16.6359px;
  background: url(../../Assets/Images/testimonials/testimonials.png);
  background-size: cover;
  background-position: center;
  position: relative;
  background-attachment: fixed;
  color: #fff;
}

.testimonials::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.8;
  content: "";
}

.testimonialsContainer {
  background-color: #000;
  padding-top: 3rem;
  box-sizing: border-box;
}

.testimonial {
  padding: 20px 20px;
}

.testimonialContent {
  position: relative;
}

.testimonialIcon {
  position: absolute;
  left: 0;
  font-size: 60px;
  color: #bbb;
  opacity: 0.3;
  top: -22px;
}

.testimonialSrcIcon {
  width: 80px;
  position: relative;
  display: inline-block;
  height: 80px;
  border: 2px solid #fff;
}

.testimonialInfo {
  overflow: hidden;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonialNav {
  color: #16a085;
  padding: 0;
  font-size: 50px;
  border-radius: 100%;
  background: 0 0;
  background: 0 0;
  transition: all 0.3s ease;
}

.testimonialSource {
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonialSourceContent {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  margin: 10px 0 0;
  text-transform: uppercase;
}

.testimonialSourceSubContent {
  color: #fff;
  position: relative;
  display: block;
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
}
