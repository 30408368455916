.timeline {
  position: relative;
  padding: 20px 0;
}

.timelineItem {
  padding-right: 0px;
  margin-bottom: 30px;
}

.timelineContent {
  width: 73%;
  background: #fff;
  padding: 25px;
  position: relative;
  box-shadow: 1px 1px 6px rgb(20 20 20 / 40%);
}

.timelineTitle {
  font-size: 14px;
  position: relative;
  padding-bottom: 12px;
  margin-bottom: 12px;
  display: inline-block;
  text-transform: uppercase;
}

.timelineTitle::before {
  position: absolute;
  bottom: -1px;
  content: "";
  left: 0;
  width: 50px;
  height: 2px;
  background: #16a085;
}

.timelineContent::before {
  border: 15px solid transparent;
  border-left-color: #ccc;
  right: -31px;
  top: 29px;
  content: "";
  position: absolute;
  content: "";
}

.timelineContent::after {
  border: 15px solid transparent;
  border-left-color: #fff;
  right: -30px;
  top: 26px;
  content: "";
  position: absolute;
  content: "";
}

.timelineDate {
  position: absolute;
  right: -90px;
  padding: 5px;
  border-radius: 5px;
  width: 60px;
  text-align: center;
  font-weight: 700;
  background-color: #16a085;
}

.timelineDateDisplay {
  color: #fff;
  margin: 0;
}

@media all and (min-width: 768px) {
  .timelineInner {
    position: relative;
    padding: 20px 0;
  }

  .timelineInner::before {
    background: #16a085;
    content: "";
    border-radius: 2px;
    margin-left: -2px;
    position: absolute;
    display: block;
    height: 100%;
    width: 4px;
    left: 50%;
    top: 0;
  }

  .timelineItem {
    padding-right: 120px;
  }

  .timelineItem:nth-child(2n) {
    padding-right: 0;
    padding-left: 120px;
  }

  .timelineContent {
    width: 50%;
  }

  .timelineItem:nth-child(2n) .timelineContent {
    margin-left: 50.4%;
  }

  .timelineItem:nth-child(2n) .timelineDate {
    left: -93px;
    right: auto;
  }

  .timelineItem:nth-child(2n) .timelineContent::before {
    right: auto;
    left: -31px;
    border-left-color: transparent;
    border-right-color: #ccc;
  }

  .timelineItem:nth-child(2n) .timelineContent::after {
    left: -30px;
    border-left-color: transparent;
    border-right-color: #fff;
    right: auto;
  }
}
