.verticalNav {
  left: 3rem;
  list-style: none;
  position: absolute;
  top: 3.4rem;
}

.verticalNavItem {
  float: none;
  margin: 0;
  padding-bottom: 5rem;
  position: relative;
}

.verticalNavItemTrail::before {
  background: #16a085;
  bottom: 0;
  content: "";
  height: 100%;
  left: 1.8rem;
  position: absolute;
  top: 0;
  width: 0.2rem;
}

.verticalNavTooltip {
  background: #16a085;
  border-radius: 5px;
  color: #fff;
  font-size: 1.2rem;
  left: -1.6rem;
  opacity: 1;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: -3rem;
  transition: all 0.3s ease;
  visibility: visible;
  width: 7.8rem;
}

.verticalNavBtn {
  background: #fff;
  border: 0;
  border-radius: 100%;
  color: #353535;
  display: block;
  height: 4rem;
  line-height: 4rem;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: center;
  width: 4rem;
}

.verticalNavBtnSelected {
  background: #16a085;
  color: #fff;
}
