.noResults {
  font-size: 2.2rem;
}

.noResultsQuote {
  font-style: italic;
}

.sectionBlog {
  padding: 2.5rem 0;
}

.searchContainer {
  padding-top: 2.5rem;
}
