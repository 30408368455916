.blogContainer {
  padding-bottom: 2rem;
}

.blogContainerImageContainer {
  display: flex;
  justify-content: center;
}

.blogContainerImage {
  max-width: 100%;
}

.blogContainerDate {
  font-style: italic;
  font-size: 2rem;
  color: white;
}

.blogContainerContent {
  margin: 0 1rem;
  font-size: 1.6rem;
}

/* Not BEM but content from Wordpress */
.blogContainerContent p {
  padding-top: 3rem;
}

.blogContainerContent h2 {
  padding-top: 3rem;
}

.blogContainerContent ul,
ol {
  margin-top: 1rem;
  margin-left: 2rem;
}

.blogContainerContent code {
  font-family: monospace;
  background-color: black;
  color: white;
  display: block;
  padding: 1.4rem;
}

.blogHeader {
  display: flex;
  justify-content: space-between;
  padding: 0.35rem;
}

.blogMetaContainer {
  font-family: monospace;
  display: flex;
  align-items: center;
}

.blogMeta {
  margin-left: 2rem;
}

.blogSection {
  padding: 2rem 0;
}

#blogTitle {
  margin-bottom: 0;
}

.excerpt {
  font-style: italic;
  font-size: 3rem;
  margin-top: 2rem;
  color: #16a085;
  line-height: normal;
}

.infoContainerHigher {
  margin-top: 6rem;
}

.loaderContainer {
  margin-top: 3rem;
}

.sectionSlim {
  padding: 0 0;
}

.sectionBlogMetaContainer {
  padding: 2rem 0;
}

@media all and (min-width: 768px) {
  .author {
    top: 2rem;
    left: 85%;
  }
}

@media all and (min-width: 992px) {
  .author {
    width: 10rem;
    height: 10rem;
  }

  .blogContainerDate {
    font-size: 3.5rem;
  }

  .blogContainerContent {
    font-size: 2rem;
  }
}
