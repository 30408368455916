.container {
  display: flex;
  justify-content: center;
  min-height: 30rem;
}

.img {
  font-size: 4rem;
}

.white {
  color: white;
}
