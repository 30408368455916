.promoBlockPadding {
  padding: 4rem 0;
}

.aboutMeImg {
  display: none;
}

.aboutContent {
  padding: 3rem 3rem 3rem 10rem;
}

.aboutContentText {
  color: #eee;
  font-weight: 300;
  margin-bottom: 2rem;
  font-size: 1.7rem;
}

.aboutPanel {
  min-height: 30rem;
  transition: all 0.4s ease;
}

@media all and (min-width: 768px) {
  .aboutContentText {
    font-size: 2rem;
  }

  .skillContainer {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
}

@media all and (min-width: 992px) {
  /* About section */

  .aboutPanelFlex {
    display: flex;
  }

  .aboutMeImg {
    display: block;
    padding-right: 2rem;
  }
}
