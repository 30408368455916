.buttonTooltip {
  border: none;
}

.categoryContainer {
  display: flex;
  margin-top: 1rem;
}

.categoryIcon {
  color: #a11f2a;
}

.iconContainer {
  margin-left: 1rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
