.promoBlock {
  background-color: #333333;
  border-radius: 10px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  position: relative;
}

.promoBlockContent {
  padding: 3rem 3rem 3rem 10rem;
}

.promoBlockContentNoNav {
  padding-left: 3rem;
}

.promoBlockPanel {
  min-height: 30rem;
  transition: all 0.4s ease;
}

.promoBlockImg {
  display: none;
}

.promoBlockText {
  color: #eee;
  font-weight: 300;
  margin-bottom: 2rem;
  font-size: 1.7rem;
}

@media all and (min-width: 768px) {
  .promoBlock {
    margin-top: -10rem;
    z-index: 3;
  }

  .promoBlockText {
    font-size: 2rem;
  }
}

@media all and (min-width: 992px) {
  .promoBlockFlex {
    display: flex;
  }

  .promoBlockImg {
    display: block;
    padding-right: 2rem;
  }
}
