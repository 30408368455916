.paginator {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paginatorItem {
  list-style-type: none;
  border: 1px solid #353535;
  border-right: none;
}

.paginatorItemSelected {
  border: 1px solid #16a085;
  background-color: #16a085;
}

.paginatorItem:last-child {
  border-right: 1px solid black;
}

.paginatorItemLink {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;
}

.paginatorItemLink:hover {
  cursor: pointer;
}

.paginatorItemLinkSelected {
  color: white;
}

@media all and (min-width: 420px) {
  .paginatorItemLink {
    width: 5rem;
    height: 5rem;
    font-size: 1.7rem;
  }
}
