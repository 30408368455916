.btn {
  width: 90px;
  height: 90px;
  line-height: 90px;
  font-size: 36px;
  position: relative;
  border-radius: 100%;
  border: 0;
  background: #16a085;
  color: #000;
}

.btnSelected::after {
  box-sizing: border-box;
  transform: scale(1.2);
  opacity: 1;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border: 3px solid #16a085;
  border-radius: 100%;
}
