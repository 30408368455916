/* Clients */

.clients {
  padding: 70px 0 70px;
}

.clients::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 101%;
  background: #000;
  opacity: 0.8;
  content: "";
}

.clientContainer {
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  text-align: center;
  justify-content: space-between;
}

.client {
  width: 30%;
}

.clientImg {
  width: 85%;
}
