.postActions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
}

.postDate {
  font-style: italic;
}

.postSummary {
  margin: 2rem 1rem;
}

.postTitleContainer {
  display: flex;
  justify-content: space-between;
}

@media all and (min-width: 992px) {
  .postContainer {
    font-size: 1.8rem;
  }
}
