.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

@media all and (min-width: 768px) {
  .container {
    width: 75rem;
  }
}

@media all and (min-width: 992px) {
  .container {
    width: 97rem;
  }
}

@media all and (min-width: 1200px) {
  .container {
    width: 117rem;
  }
}
