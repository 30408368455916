.sectionTitle {
  margin-left: 15px;
  margin-right: 15px;
  padding: 0 10px;
  margin-bottom: 30px;
  text-align: center;
}

.sectionTitleHeader {
  display: inline-block;
  color: #353535;
  font-size: 21px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  text-transform: uppercase;
}

.sectionTitleWhite {
  color: #fff;
}

.sectionTitleHeader::before {
  background: #16a085;
  position: absolute;
  bottom: -1px;
  content: "";
  left: 50%;
  margin-left: -35%;
  width: 70%;
  height: 2px;
  border-radius: 100%;
}

.sectionTitleIcon {
  display: inline-block;
  background: #16a085;
  position: absolute;
  left: 50%;
  top: 83%;
  font-size: 15px;
  color: #fff;
  border-radius: 100%;
  width: 1em;
  height: 1em;
  text-align: center;
  padding: 3px;
  line-height: 20px;
  border: none;
  margin-left: -10px;
}

.sectionTitleSubTitle {
  color: #666;
}
