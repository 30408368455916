/* Transitions */

.typed-enter {
  width: 0px;
}

.typed-enter-active {
  width: 300px;
  transition: width 1400ms;
}

.typed-exit {
  width: 300px;
}

.typed-exit-active {
  width: 0px;
  transition: width 1400ms;
}
