.cardItem {
  position: relative;
  transition: all 0.3s ease;
  overflow: hidden;
  height: 240px;
}

.cardImage {
  position: relative;
}

.cardImage::before {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(5, 5, 5, 0.9);
  content: "";
  visibility: visible;
  transition: all 0.4s ease;
  transform: translateX(-100%);
  left: 0;
  z-index: 11;
}

.cardImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.cardItem:hover .cardImage::before {
  visibility: visible;
  transform: translateX(0);
}

.cardItemDetails {
  position: absolute;
  top: 0;
  z-index: 20;
  color: #fff;
  top: 50%;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
  opacity: 0;
  transition: all 0.4s ease;
}

.cardItem:hover .cardItemDetails {
  opacity: 1;
  visibility: visible;
}

.cardItemDetails {
  display: block;
  background: rgba(1, 1, 1, 0.7);
  height: 218px;
  position: relative;
  top: -218px;
  color: white;
}

.cardCategory {
  color: #16a085;
  font-size: 16px;
  display: block;
  font-style: italic;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.cardTitle {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
}

.cardInfo {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 1.2rem;
}

@media all and (min-width: 420px) and (max-width: 768px) {
  .cardItem {
    height: 500px;
  }

  .cardItemDetails {
    height: 500px;
    top: -500px;
    padding-top: 150px;
  }
}

@media all and (min-width: 768px) {
  .cardItem {
    width: 50%;
  }
}

@media all and (min-width: 992px) {
  .cardItem {
    width: 33.33%;
  }
}
