.btn {
  border: none;
  border-radius: 0.5rem;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 400;
  text-transform: uppercase;
  margin-right: 10px;
  overflow: hidden;
  padding: 12px 18px;
}

.btn:hover {
  cursor: pointer;
}

.btnGreen {
  background: #16a085;
  color: #fff;
}

.btnWhite {
  background: #fff;
  color: #353535;
}

.btnIcon {
  margin-right: 1rem;
}
