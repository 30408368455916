.progress {
  background-color: #f5f5f5;
  height: 0.8rem;
  overflow: visible;
}

.progressBar {
  background-color: #16a085;
  height: 100%;
  text-align: center;
  transition: width 0.6s ease;
  position: relative;
}

.progressBarLabel {
  background: #16a085;
  border-radius: 100%;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 400;
  height: 3rem;
  line-height: 3rem;
  position: absolute;
  right: 0;
  top: -13px;
  width: 3rem;
}

.progressBarItem {
  margin-top: 1.5rem;
}

.progressBarItem h4 {
  overflow: hidden;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 2.2rem;
  text-transform: uppercase;
}

@media all and (min-width: 768px) {
  .progressBarItem {
    width: 48%;
  }
}
