.nav {
  padding: 0 1.5rem;
  width: 100%;
  order: 2;
}

.menu {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style-type: none;
}

@media all and (min-width: 768px) {
  .menu {
    justify-content: center;
  }
}

@media all and (min-width: 992px) {
  .nav {
    width: auto;
  }

  .menu {
    align-items: flex-start;
    background: none;
    flex-wrap: nowrap;
  }
}
