.typeAheadChanger {
  display: block;
  overflow: hidden;
  position: relative;
  text-align: left;
}

.typeAheadChangerItem {
  color: #16a085;
  font-weight: 700;
  left: 0;
  position: relative;
  top: 4px;
  white-space: nowrap;
}

.typeAheadTitle {
  color: #eee;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.2rem;
  margin-bottom: 2.5rem;
  text-transform: uppercase;
}

@media all and (min-width: 420px) {
  .typeAheadChanger {
    display: inline-block;
  }
}

@media all and (min-width: 992px) {
  .typeAheadChangerItem {
    top: 0px;
  }

  .typeAheadTitle {
    font-size: 2.5rem;
  }
}
