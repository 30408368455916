.menuItem {
  order: 3;
  padding: 2.25rem 0.5rem;
  width: 100%;
}

.menuItemLink {
  background-color: inherit;
  border: 0;
  color: white;
  padding: 1.5rem 0.5rem;
  cursor: pointer;
}

.menuItemLink:hover,
.menuItemLink:hover::after {
  color: #ccc;
}

.menuItemLinkIcon {
  margin-right: 1rem;
}

.menuItemLogo {
  font-size: 2rem;
  order: 0;
  padding: 0.75rem 0.1rem 0.75rem 1.5rem;
  text-align: left;
  flex-grow: 2;
}

.menuItemLogoLink {
  display: block;
  padding: 1.5rem 0.5rem;
}

.menuItemLogoLink::before {
  background-color: #16a085;
  border-radius: 100%;
  content: "";
  height: 1rem;
  width: 1rem;
  display: inline-block;
  margin-right: 0.5rem;
}

.menuItemToggle {
  font-size: 2rem;
  order: 1;
}

.menuItemToggleLabel {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.188em;
  padding-right: 0.5rem;
  vertical-align: middle;
}

@media all and (min-width: 768px) {
  .menuItemToggle {
    flex: 1;
    order: 2;
    text-align: right;
  }
}

@media (orientation: landscape) and (max-width: 992px) {
  .menuItem {
    padding: 1rem 0.5rem;
  }
}

@media all and (min-width: 992px) {
  .menuItem {
    display: block;
    order: 1;
    position: relative;
    width: auto;
  }

  .menuItemLogo {
    flex: 1;
    order: 0;
    text-align: left;
  }

  .menuItemToggle {
    display: none;
  }
}
